import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { NavBrand, NavBrandMobile, NavContainer, NavMenu, NavWrapper } from "./Navbar.style";

class Navbar extends Component {
  state = {
    active: ""
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let scroll = window.scrollY;

    if (scroll > 0) {
      this.setState({ active: "active-nav" });
    } else {
      this.setState({ active: "" });
    }
  };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <NavContainer className={this.state.active}>
        <NavWrapper>
          <a href="#top">
            <NavBrandMobile onClick={this.scrollToTop} >
              LG
            </NavBrandMobile>
          </a>
          {/* <NavBrand href="#top">
            <span
              onClick={this.scrollToTop}
            >LOUIS GELINAS</span>
          </NavBrand> */}
          <NavMenu>
            <ul>
              <li className="page-links">
                <Link
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Home
                </Link>
              </li>
              <li className="page-links">
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={500}
                >
                  About
                </Link>
              </li>
              <li className="page-links">
                <Link
                  activeClass="active"
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Work
                </Link>
              </li>
              <li id="resume">
                <a href={require("../../_assets/louis_gelinas_resume.pdf")} target="_blank" rel="noopener noreferrer">
                  Resume
                </a>
              </li>
            </ul>
          </NavMenu>
        </NavWrapper>
      </NavContainer>
    );
  }
}

export { Navbar };
