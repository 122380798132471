// This component is a hard-coded project section so that if
// the server is down, the project section of the portfolio
// will not break
import React from "react";

import { ProjectGallery, Project } from "./Projects.style";

const StaticProjectGallery = () => {
  return (
    <ProjectGallery>

      <Project>
        <div className="column-reverse">
          <div className="project-description">
            <h3>DEPLOYED PRODUCT</h3>
            <h2>Our Community Calendar</h2>
            <p className="long-description">
            Create incredible neighborhoods and community spaces through meaningful shared events.
              <br />
              <br />
              An immaculately designed portal tailored to the needs of neighborhoods and small community groups. Cut through the noise of crowded social media sites and discover the events, block clubs, artist collectives, religious groups, and friends that have been in your neighborhood this entire time.
              <br />
              <br />
              Tech Stack: Apollo Server, Prisma ORM, GraphQL, React
            </p>

            <div className="btn-group">
              <a
                className="app-btn"
                href="https://www.ourcommunitycal.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                visit
              </a>
              <a
                className="src-btn"
                href="https://github.com/Lambda-School-Labs/community-calendar-fe"
                target="_blank"
                rel="noopener noreferrer"
              >
                client
              </a>
              <a
                className="src-btn"
                href="https://github.com/Lambda-School-Labs/community-calendar-be"
                target="_blank"
                rel="noopener noreferrer"
              >
                api
              </a>
            </div>
          </div>

          <div className="project-thumbnail inverted-thumbnail">
            <img
              src={require("../../_assets/img/project-comcal.png")}
              alt="API documentation"
            />
          </div>
        </div>
      </Project>

      <Project>
        <div className="project-thumbnail">
          <img
            src={require("../../_assets/img/project-api.png")}
            alt="API documentation"
          />
        </div>

        <div className="project-description">
          <h3>PERSONAL PROJECT</h3>
          <h2>Block Club Calendar API v2</h2>
          <p>
            A flexible API and database ready to grow with your community.
            <br />
            <br />
              Block Club Calendar Backend v2 increases data persistence and provides a much more expansive schema. Features included in v2 include options for community managers to review and approve events as well as assign specific roles to persons and organizations within their community.
            <br />
            <br />
            Tech Stack: Node-Express, PostgreSQL
          </p>

          <div className="btn-group">
            <a
              className="app-btn"
              href="https://www.appraiserbff.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              visit
            </a>
            <a
              className="src-btn"
              href="https://github.com/bw-appraisers-bff"
              target="_blank"
              rel="noopener noreferrer"
            >
              code
            </a>
          </div>
        </div>
      </Project>

      <Project>
        <div className="column-reverse">
          <div className="project-description">
            <h3>TEAM PROJECT</h3>
            <h2>Appraiser BFF</h2>
            <p className="long-description">
            Instant property value insights at your fingertips.
              <br />
              <br />
                Leveraging a machine learning algorithm and exapansive database of tax
                assessments, Appraiser BFF provides agents, brokers, homeowners and buyers 
                the fastest and most relevant valuations on the market.
              <br />
              <br />
              Tech Stack: React, Redux, Node-Express, PostgreSQL
            </p>

            <div className="btn-group">
              <a
                className="app-btn"
                href="https://"
                target="_blank"
                rel="noopener noreferrer"
              >
                docs
              </a>
              <a
                className="src-btn"
                href="https://github.com/bw-block-club-calendar-be"
                target="_blank"
                rel="noopener noreferrer"
              >
                code
              </a>
            </div>
          </div>

          <div className="project-thumbnail inverted-thumbnail">
            <img
              src={require("../../_assets/img/project-appraiserbff2.png")}
              alt="Appraiser BFF website"
            />
          </div>
        </div>
      </Project>

      <Project>
        <div className="project-thumbnail">
          <img
            src={require("../../_assets/img/project-blockclub.png")}
            alt="Block Club Calendar"
          />
        </div>

        <div className="project-description">
          <h3>TEAM PROJECT</h3>
          <h2>Block Club Calendar</h2>
          <p>
            Connect with you local community without the noise of social media.
            <br />
            <br />
            An alpha product developed specifically for Detroit's North End Alliance, the Block Club Calendar full-stack application allows any community to create a calendar curated just for their local events. Find out what is going on in your community and foster engagement through a simple and intuitive user experience. A subsequent design sprint and user testing resulted in the development of Our Community Calendar.
            <br />
            <br />
            Tech stack: React, Node-Express, SQLite3
          </p>

          <div className="btn-group">
            <a
              className="app-btn"
              href="https://blockclubcalendar.now.sh/"
              target="_blank"
              rel="noopener noreferrer"
            >
              visit
            </a>
            <a
              className="src-btn"
              href="https://github.com/Build-Week-Block-Club-Calendar/block-club-calendar-FE"
              target="_blank"
              rel="noopener noreferrer"
            >
              code
            </a>
          </div>
        </div>
      </Project>
    </ProjectGallery>
  );
};

export default StaticProjectGallery;
