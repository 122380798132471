import React from "react";
import StaticProjectGallery from "./StaticProjectGallery";

import { ProjectContainer } from "./Projects.style";

const Projects = () => {
  return (
    <ProjectContainer id="projects">
      <h1>Work.</h1>
      <p>
        Here are a few of the products I've launched as a web developer.
        <br /> You can view my other work on{" "}
        <a
          href="https://github.com/gelinas"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
        .
      </p>

      <StaticProjectGallery />
    </ProjectContainer>
  );
};

export { Projects };
