import React from "react";
import { AboutContainer, AboutHeadline, AboutImage } from "./About.style";

const About = () => {
  return (
    <AboutContainer id="about">
      <AboutHeadline>
        <h1>LOUIS GELINAS</h1>
        <p className="main-headline">
          Louis Gelinas is a Full Stack Web Developer with 8 years experience giving 
          teams, communities, and businesses the tools they need to grow.
          <br /> 
          <br /> 
          In his previous career, he led cross-functional teams for the U.S. Army
          to accomplish military and diplomatic objectives in Korea, Ukraine, 
          Afghanistan, and other NATO Allied countries.
        </p>
      </AboutHeadline>

      <AboutImage>
        <img
          src={require("../../_assets/img/louis-headshot.jpg")}
          alt="Louis Gelinas"
        />
      </AboutImage>
    </AboutContainer>
  );
};

export { About };
