import styled from "styled-components";
import { flex, wrapper } from "../../_styles/mixins.style";
import { buttonLight, colors, buttonDark } from "../../_styles/variables.style";

export const NavContainer = styled.div`
  ${wrapper()}
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 0px;
  z-index: 2;
  background-color: ${colors.whiteRegular};
  transition: 150ms ease-in;
`;

export const NavWrapper = styled.div`
  ${wrapper("80%", "0 auto")}
  ${flex("space-between", "center")}

@media (max-width: 500px) {
  padding: 1rem 0;
}
`;

export const NavBrand = styled.a`
margin: 3rem 0rem;
  span {
    ${buttonDark}
  @media (max-width: 500px) {
    display: none;
  }
}
  img {
    width: 4.5rem;
    height: auto;
  }     
`;

export const NavBrandMobile = styled.div`
  color: #fff;
  background-color: #4682B4;
  width: 55px;
  height: 55px;
  /* display: none; */
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 2.0rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;

  @media (max-width: 500px) {
    padding: 1rem 2rem;
    /* display: flex; */
    width: 45px;
    height: 45px;
  }

  img {
    width: 4.5rem;
    height: auto;
  }     
`;

export const NavMenu = styled.nav`
  .active {
    color: ${colors.blackLight};
    border-bottom: 2px solid ${colors.blackLight};
    padding-bottom: 0.5rem;
    transition: 150ms ease-in;
  }

  ul {
    ${flex("space-between", "center")}
    width: 100%;
    min-width: 50rem;
    text-align: center;
    @media (max-width: 700px) {
      min-width: 0;
    }

    .page-links {
      @media (max-width: 700px) {
        display: none;
      }
    }

    li {
      margin-left: 3.5rem;
      width: 25%;

      a {
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        transition: 150ms ease-in;
        cursor: pointer;

        &:hover {
          color: ${colors.blackLight};
          border-bottom: 2px solid ${colors.blackLight};
          padding-bottom: 0.5rem;
        }
      }
      a:active {
        color: ${colors.blackLight};
        border-bottom: 2px solid ${colors.blackLight};
        padding-bottom: 0.5rem;
      }
    }

    li:first-child {
      margin: 0;
    }

    li:last-child {
      a {
        ${buttonLight}

        @media (max-width: 500px) {
          padding: 1rem 2rem;
        }
      }
    }
  }
`;
